<template>
    <div class="wrapper has_nav_bar">
        <van-nav-bar title="我的报名" @click-left="goHome" left-text="返回" left-arrow fixed/>
        <div class="content">
            <div class="main-content">
                <div class="application-list">
                    <h2 class="van-cell-group-title">报名列表<span class="extra-info">共{{ total }}个报名</span>
                    </h2>
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                    >
                        <div class="app-block" v-for="item in dataList" v-if="dataList!=null ">
                            <div class="app-name">
                                <span>{{ item.positionName }}</span>
                                <van-tag plain type="warning"
                                         v-if="item.auditResource==='未上传' && item.statusName==='待资审'">待提交资审资料
                                </van-tag>
                                <van-tag plain v-else>{{ item.statusName }}</van-tag>
                            </div>

                            <div class="app-misc">
                                <div class="app-info">
                                    <div class="app-company">{{ item.companyName }}</div>
                                    <div class="app-time">{{ item.submitTime }}</div>
                                </div>
                                <div class="app-btn">
                                    <van-button type="warning"
                                                v-if="item.auditResource==='未上传' && item.statusName==='待资审'"
                                                size="small" @click="goSubmitQualification(item.id)">提交
                                    </van-button>
                                    <van-button type="primary"
                                                size="small" @click="goJobDetail(item.positionCode)">查看
                                    </van-button>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </div>
            </div>
        </div>
        <div class="footer">
        </div>
    </div>
</template>

<script>
import {getProjectByCode, reqApplicationPage} from "../../api/client-api";

export default {
    data() {
        return {
            statusList: [],
            viewForm: {},
            projectCode: null,
            loading: false,
            finished: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            dataList: [],
        }
    },
    methods: {
        getApplication() {
            if (sessionStorage.getItem('Authorization')) {
                this.loading = true;
                let para = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                };
                reqApplicationPage(para).then((res) => {
                    if (this.pageNum === 1) {
                        this.total = res.data.data.total
                        this.dataList = res.data.data.records
                    } else {
                        this.dataList = this.dataList.concat(res.data.data.records)
                    }
                    this.loading = false;
                    this.pageNum++;
                    if (res.data.data.records.length === 0 || res.data.data.records === null || res.data.data.records.length < 1) {
                        this.finished = true
                        return
                    }
                }).catch(() => {
                    this.loading = false;
                    this.dataList = [];
                })
            } else {
                let redirectTo = window.location.href;
                sessionStorage.setItem('redirectTo', redirectTo);
                this.$router.push({name: 'login', query: {projectCode: this.projectCode}});
            }
        },
        goJobDetail(code) {
            this.$router.push({name: 'position', params: {id: code}});
        },
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.viewForm = res.data.data;
                }
            }).catch((err) => {
                console.log('err===', err)
            })
        },
        goHome() {
            this.$router.push({name: 'project', params: {id: this.projectCode}});
        },
        goSubmitQualification(id) {
            this.$router.push("/submitQualification/" + id)
        },
        onLoad() {
            this.getApplication()
        }
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.getProject()
    }
};
</script>

<style scoped>

</style>
