<template>
    <div class="wrapper">
        <Header :code="projectCode"></Header>
        <div class="content">
            <div class="main-content">
                <div class="breadcrumb">
                    <div class="tips">共投递 {{ total }} 个职位</div>
                    <Breadcrumb>
                        <BreadcrumbItem @click.native="goHome(projectCode)">主页</BreadcrumbItem>
                        <BreadcrumbItem>我的报名</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div class="my-apply">
                    <Table stripe :columns="columns" :data="dataList" :loading="loading">
                        <template slot-scope="{ row, index }" slot="action">
                            <a v-if="row.auditResource==='未上传' && row.statusName==='待资审'" @click="goSubmitQualification(row.id)">提交资料<Divider type="vertical"/></a>
                            <a @click="goJobDetail(row.positionCode)">查看详情</a>
                        </template>
                    </Table>
                </div>
                <div class="table-footer">
                    <Row>
                        <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                        <Col class="pagenation" span="18">
                            <Page :page-size="pageSize" :total="total" :current="pageNum"
                                  :page-size-opts="[15, 20, 50, 100]" @on-change="changePage"
                                  @on-page-size-change="changPageSize" show-sizer show-elevator/>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import {getProjectByCode, reqApplicationPage} from "../../api/client-api";
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer";

export default {
    components: {
        Header: Header,
        Footer: Footer
    },
    data() {
        return {
            statusList: [],
            viewForm: {},
            projectCode: null,
            loading: false,
            dataList: [],
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '职位名称',
                    key: 'positionName',
                    width: 300,
                },
                {
                    title: '公司名称',
                    key: 'companyName',
                    minWidth: 240,
                },
                {
                    title: '报名时间',
                    key: 'submitTime',
                    width: 200,
                },
                {
                    title: '应聘状态',
                    width: 130,
                    render: (h, params) => {
                        return h('span', {
                                style: {color: params.row.auditResource === '未上传' && params.row.statusName==='待资审' ? '#ff9900' : ''}
                            }, (params.row.auditResource === '未上传' && params.row.statusName==='待资审' ? '待提交资审资料' : params.row.statusName));
                    }
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 180,
                    align: 'center',
                },
            ]
        }
    },
    methods: {
        getApplication() {
            this.loading = true;
            this.dataList = [];
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            reqApplicationPage(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            })
        },
        goJobDetail(code) {
            this.$router.push({name:'position',params:{id: code}});
        },
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.viewForm = res.data.data;
                }
            }).catch((err) => {
                console.log('err===', err)
            })
        },
        goHome(code) {
            this.$router.push({name: 'project', params: {id: code}});
        },
        goSubmitQualification(id) {
            this.$router.push("/submitQualification/"+id)
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getApplication();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getApplication();
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.getProject()
        this.getApplication()
    }

};
</script>

<style scoped>

</style>
